input[type="file"] {
    display: none;
}

.PageUploadTransportadora{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column; 
  align-items: center
}

.custom-file-upload {
    border: 1px solid #9E3FFD;
    color: #9E3FFD;
    display: inline-block;
    border-radius: 10px;
    padding: 6px 12px;
    cursor: pointer;
    transition: 1s;
}


.custom-file-upload:hover {
  border: 1px solid #9E3FFD;
  background-color: #9E3FFD;
  color: white;
  display: inline-block;
  border-radius: 10px;
  padding: 6px 12px;
  cursor: pointer;
  transition: 1s;
}


.button-file-upload {
  border-radius: 8px;
  color: white;
  background-color: #9E3FFD;
  border: solid #9E3FFD 1px ;
  transition: 1s;
  cursor: pointer;
  height:  40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 1s;
  width: 250px;
}

.button-file-upload:hover{
    background: white;
    color: #9E3FFD;
    border: solid #9E3FFD 1px ;
    font-weight: bold;
    transition: 1s;
    width: 250px;
}

.tableUpload {
    border-collapse: collapse;
    width: 100%;
    table-layout:fixed;
  }


  .rowUpload:nth-child(odd) {
    background-color: #f2f2f2;
  }

  .itemTableUpload {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
    width: 150px;
  }

  .rowUpload:nth-child(1) {
    background-color: #9E3FFD;
    color: white;
    text-align: center;
  }


  .divAlert {
    padding: 10px 20px 10px 20px;
    background-color: rgb(255, 255, 103);
    border-radius: 50px;
    display: flex;
    gap: 20px 20px;
    align-items: center;  
    font-weight: 600;
  }

  .divSuccess {
    padding: 10px 20px 10px 20px;
    background-color: rgb(126, 255, 130);
    border-radius: 50px;
    display: flex;
    gap: 20px 20px;
    align-items: center;  
    font-weight: 600;
  }

  .divErro {
    padding: 10px 20px 10px 20px;
    background-color: rgb(255, 103, 103);
    border-radius: 50px;
    display: flex;
    gap: 20px 20px;
    align-items: center;  
    font-weight: 600;
  }
  

  
 
  
  @media screen and (max-width: 600px) {
    .tableUpload {
      font-size: 14px;
    }
  }
  