.ServiceCardContainer {
    width: 25%;
    border-radius: 9px;
    background-color: white;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    padding :1rem;
    transition: 1s;
    position: relative;
}

@media (max-width: 768px) {
    .ServiceCardContainer {
      width: 100% !important;
      margin-top: 2vh;
    }
  }

  @media (max-width: 1200px) {
    .ServiceCardContainer {
      width: 40%;
    }
  }

.ServiceCardContainer:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    transition: 1s;
}

.ServiceCardHeader {

    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    /* border: solid green 1px; */
    
}

.divButton{
    position: absolute;
    bottom: 10px;  
    width: 90%;
    display: flex;
}

.ServiceCardTitle{
    font: 1.5rem;
    font-weight: bold;
    text-align: left;
}

.cardInfoText {
    width: 100%;
    margin-bottom: 2rem;
    /* border: solid yellow 2px; */
}

.ServiceCardText{
    color: black;
    font: 0.8rem;
    text-align: left;
        /* border: 1px solid red; */
    width: fit-content;
    padding-bottom: 2rem;
}