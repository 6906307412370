.PageLogin{
display: flex;
align-items: center;
flex-direction: column;
height: 130vh;

}

.LogoFlow {
    width: 80vw;
}

@media(min-width: 1000px){
    .LogoFlow {
        width: 30rem;
    }
}