.BoxInputSuporte {
    border: 1px solid rgb(43, 43, 43);
    padding: 1vw;
    border-radius: 10px;
    width: max-content;
}

.titleBox {
    font-size: 1vw;
    width: 100%;
}

.subTitleBox {
    font-size: 0.7vw;
    color: grey;
    width: 100%;
}