.CardTransportadoraContainer {
    width: max-content;
    border: none;
    padding: 1%;
    text-align: left;
    border-radius: 10px;
    transition: 1s;
    border: 1px solid #949494;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.CardTransportadoraContainer:hover {
    border: 1px solid white;
    transition: 1s;
    background-color: white;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.CardTransportadoraContainer:hover .fotoResultTransportadora{
    border: 1px solid #9E3FFD;
    transition: 1s;
 }
 

.fotoResultTransportadora {
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: 1px solid white;
    border-radius: 10px;
    transition: 1s;
}

.titleResultTransportadora {
    font-size: 1vw;
}

.valorResultTransportadora {
    font-size: 1.5vw;
}

.SubTitleResultTransportadora {
    color: grey;
}