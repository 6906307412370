.StatusCards {
    display: flex;
    align-items: left;
    margin: 1rem;
}

.ServiceCards {

    display: flex;
    align-items: left;
    margin: 1rem;

}

.WelcomeMessage {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;

    margin-left: 3rem;

    /* width: 100%; */
    border: solid grenn 2px;
    margin-bottom: 3rem;
}