.MenuLateral{
    box-shadow: 0 3px 12px rgba(0,0,0,0.16), 0 3px 12px rgba(0,0,0,0.23);
    width: 15vw;
    height: 100vh;
    transition: 1s;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgb(241, 241, 241);
    overflow-y: auto;
    z-index: 10;
}

.contentMenuLateral{
    padding: 1vw;
}



.boxUser {
    color: black;
    border: 1px solid #9E3FFD;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: row;
    padding: 5%;
    border-radius: 10px;
    cursor: pointer;
    font-size: 0.7rem;
}

.photoUser{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.infoUser{
    color: grey;
    font-size: 80%;
}

.itensMenu{
    color: black;
    margin-top: 3vh;
    display: flex;
    flex-direction: column;
}

.itemMenuDisable{
    color: rgb(173, 173, 173);
    margin-bottom: 3vh;
    cursor: pointer;
    transition: 1s;
    font-size: 12px;
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    gap: 5px 5px;
}

.itemMenuDisable:hover{
    color: black;
    transition: 1s;
    
}

.itemMenuEnable {
    color:#9E3FFD !important;
    font-weight: bold;
    width: 90%;
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    padding: 5px;
    border: solid 1px #9E3FFD;
    cursor: default !important;
    margin-bottom: 3vh;
    font-size:14px;
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    gap: 5px 5px;
    transition: 0.5s;
}


.dropDownCotacao{
    background-color: #9E3FFD;
    border: 1px solid #9E3FFD;
    color: white;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    padding: 8px 5px 8px 5px;
    cursor: pointer;
    transition: 1s;
    margin-top: 20px;
}

.dropDownCotacao:hover{
    background-color: white;
    border: 1px solid #9E3FFD;
    color:#9E3FFD;
}

.dropDownCotacao:hover .boxDropDownCotacao{
    border-radius: 100%;
}

.dropDownCotacao:hover .itemDropDownCotacao{
    color:#9E3FFD;
}


.textDropDownCotacao{
    font-weight: 500;
    padding: 0;
    margin: 0;
    font-size: 12px;
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
}

.boxDropDownCotacao{
    background-color: white;
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    padding: 2px;
    margin-right: 15px;
    transition: 1s;
}

.divItensDropDownCotacao{
    margin-top: 15px;
}

.divHeadDropDownCotacao{
    width: 100%;
    display: flex;
    
}

.itemDropDownCotacao{
    color: white;
    margin-bottom: 3vh;
    cursor: pointer;
    transition: 1s;
    font-size: 12px;
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    gap: 5px 5px;
    font-weight: 500;
    padding: 5px 2px 5px 5px;
}

.itemDropDownCotacao:hover{
    background-color: #9E3FFD;
    border-radius: 8px;
    color: white !important;
}

/* width */
::-webkit-scrollbar {
    width: 7px;
    border-radius: 10%;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #c5c5c5;
    border-radius: 10%;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #989898;
    border-radius: 10%;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }