.alert {
    width: 95%;
    border-radius: 9px;
    border-left: solid #d93025 2vh;
    opacity: 1;
    display: flex;
    justify-content: space-between;
    padding-left: 5px;
    align-items: center;
    align-self: center;
    text-align: center;
  }
  
  .alert p {
    margin-left: 15px;
  }
  
  .alert-closing {
     width: 100%;
    border-radius: 9px;
    border-left: solid #d93025 2vh;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 1s ease-in-out 0.5s;
  }
  
  .warning {
    background: #fff3f2;
    color: #d93025;
    border-color: #d93025;
  }
  
  .info {
    background: #edf7ee;
    color: #00539f;
    border-color: #00539f;
  }
  
  .success {
    background: #edf7ee;
    color: #4caf50;
    border-color: #4caf50;
  }
  
  .tip {
    background: #fff5e6;
    color: #ff9800;
    border-color: #ff9800;
  }
  
  .alert-close {
    margin: 0px 10px 0px 10px;
    font-weight: bold;
    float: right;
    font-size: 20px;
    cursor: pointer;
    transition: 0.3s all;
  }
  
  .alert-close:hover {
    color: #000;
  }
  
  .alert code,
  .alert .mark {
    background: #fff;
    opacity: 0.9;
    border-radius: 4px;
    font-size: 89%;
    font-weight: normal;
  }
  