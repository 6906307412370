.BoxResultProduto {
    width: 30%;
    border: 1px solid gray;
    padding: 1%;
    padding-bottom: 1vh;
    text-align: left;
    border-radius: 10px;
    transition: 1s;
}


.BoxResultProduto:hover {
    border: 1px solid #9E3FFD;
    transition: 1s;
}


.fotoResultProduto {
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    border-radius: 10px;
}

.titleResultProduto {
    font-size: 1.5vw;
    font-weight: bold;
    padding: 1vh
}

.valorResultProduto {
    font-size: 1.5vw;
}

.SubTitleResultProduto {
    color: grey;
}


@media (min-width: 0px) {
    .BoxResultProduto{
        width: 100%;
    }

    .titleResultProduto {
        font-size: 20px;
    }

  }

  @media (min-width: 1000px) {
    .BoxResultProduto{
        width: 30%;
    }

    .titleResultProduto {
        font-size: 1.5vw;
    }
  }

  @media (min-width: 1200px) {
    
  }