.PagePerfil{

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;
}

.whitecard {
    background-color: white;
    border-radius: 2vh;
    align-self: flex-start;
}

.tableForm{
    display: flex; 
    justify-content: flex-start; 
    flex-direction:column; 
    align-items:flex-start
}

.imageCircle {

    border: 2px solid #9E3FFD;
    width: 100px;
    height: 100px;
    border-radius: 2vh;

    display: flex;
    justify-content: center;
    align-items: center;
}

.imageCircle span {
    color: gray
}

.itemTag {
    border: 2px solid grey; 
    color: grey;
    font-weight: 1000;
    border-radius: 30px; 
    padding: 5px 15px 5px 15px; 
    width: max-content; 
    line-height: 0px; 
    cursor: pointer;
    font-size: 10px;
    transition: 1s;
}


.itemTagActive {
    border: 3px solid #9E3FFD; 
    background-color: #9E3FFD;
    color: white;
    font-weight: 1000;
    border-radius: 30px; 
    padding: 5px 15px 5px 15px; 
    width: max-content; 
    line-height: 0px; 
    cursor: pointer;
    font-size: 10px;
    transition: 1s;
}

.itemTagActive:hover {
    border: 3px solid #9E3FFD; 
    background-color: #9e3ffd64;
    color: white;
    transition: 1s;

}

.itemTag:hover{
    border-color: #9E3FFD;
    color: #9E3FFD;
    transition: 1s;
    
}


.divTags {
    border: 1px solid grey; 
    border-radius: 10px; 
    padding: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px 10px;
    width: 85%; 
}
