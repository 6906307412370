.BoxInputCadastro{
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    transition: 1s;
    padding: 1vw 2vw 2vw 2vw;
    cursor: pointer;
}

.divCadastro{

    width: 95%; 
    margin: 0; 
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 2vh 2vh;
}

.BoxInputCadastro.showInputs {
    opacity: 1;
    max-height: 1000px; /* Defina um valor maior que a altura máxima esperada dos inputs */
    transition: opacity 0.5s ease, max-height 0.5s ease;
  }

.titleBox{
    font-size: 2vw;
    width: 100%;
    text-align: left;
    margin: 0;
}

.subTitleBox{
    font-size: 1vw;
    color: grey;
    width: 100%;
    text-align: left;
    margin-top: 1vh;
}

.divInputs {
    opacity: 1;
    max-height: 1000px;
    overflow: hidden;
    transition: opacity 0.3s ease, max-height 0.5s ease; /* Definindo a transição */
  }
  
  /* Classe para esconder os inputs */
  .hideInputs {
    opacity: 0;
    max-height: 0;
  }



  @media (min-width: 0px) {
    .BoxInputCadastro{
        width: 90%;
    }

    .titleBox{
        font-size: 3vw;
        width: 100%;
        text-align: left;
        margin: 0;
    }
    
    .subTitleBox{
        font-size: 2vw;
        color: grey;
        width: 100%;
        text-align: left;
        margin-top: 1vh;
    }
  }

  @media (min-width: 700px) {
    .BoxInputCadastro{
        width: 50%;
    }
  }

  @media (min-width: 1200px) {
    .BoxInputCadastro{
        width: 30%;
        overflow-x: hidden;
    }
  }
