.myDiv {

        border: 1px solid #9E3FFD;
        width: 150px;
        height: auto;
        border-radius: 2vh;
        font-size: 10px;
        padding: 6px 6px 0px 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
}

.Uploadbutton {

        border-radius: 8px;
        border-width: 0;
        color: white;
        background-color: #9E3FFD;
        cursor: pointer;
        height: 5vh;
        margin-top: 1vh;
        width: 100%;

}

.Uploadbutton:hover {

        background: white;
        color: #9E3FFD;
        border: solid #9E3FFD 2px;
        font-weight: bold;
}