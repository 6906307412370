.BoxResultCotacao{
    width: max-content;
    border: none;
    padding: 1%;
    text-align: left;
    border-radius: 10px;
    transition: 1s;
    border: 1px solid #949494;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    
}

.BoxResultCotacao:hover{
    border: 1px solid white;
    transition: 1s;
    background-color: white;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.BoxResultCotacao:hover .fotoResultCotacao{
   border: 1px solid #9E3FFD;
}


.fotoResultCotacao{
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: 1px solid #949494;
    border-radius: 10px;
}

.titleResultCotacao{
    font-size: 1vw;
}

.valorResultCotacao{
    font-size: 1.5vw;
}

.SubTitleResultCotacao{
    color: grey;
}

@media (min-width: 0px) {
    

    .titleResultCotacao{
        font-size: 22px;
    }
    
    .valorResultCotacao{
        font-size: 30px;
    }
    
    .SubTitleResultCotacao{
        font-size: 15px;
        color: grey;
    }
    
  }

  @media (max-width: 500px) {
    
    .BoxResultCotacao{

        padding: 1.5rem;
    }
  }
