.BoxResultEtiquetas{
    width: 25%;
    background-color: white;
    border: 1px solid white;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    padding: 2%;
    padding-top: 0;
    text-align: left;
    padding-right: 5vw;
    border-radius: 10px;
    transition: 1s;

}

.BoxResultEtiquetas:hover{
    border: 1px solid #9E3FFD;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    transition: 1s;
}


.idResultEtiquetas{
    font-size: 20px;
}

.nomeResultEtiquetas{
    font-size: 0.9vw;
    margin-top: 5px;
    margin-bottom: 10px;
}

.fornecedorResultEtiquetas{
    color: grey;
}

@media (min-width: 0px) {
    
    
    .idResultEtiquetas{
        font-size: 20px;
    }
    
    .nomeResultEtiquetas{
        font-size: 20px;
    }
    
    .fornecedorResultEtiquetas{
        color: grey;
    }
  }

  @media (min-width: 1200px) {
   
  }