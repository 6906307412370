.PageTabelaPreco{

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;
}

.whitecard {
    background-color: white;
    border-radius: 10px;
    align-self: flex-start;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    padding: 15px 30px 30px 30px;
    width: auto;
}

.tableForm{
    display: flex; 
    justify-content: flex-start; 
    flex-direction:column; 
    align-items:flex-start
}

.imageCircle {

    border: 2px solid #9E3FFD;
    width: 100px;
    height: 100px;
    border-radius: 2vh;

    display: flex;
    justify-content: center;
    align-items: center;
}

.imageCircle span {
    color: gray
}
