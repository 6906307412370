.ModalSuport {
    width: 100%;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 1s;
    opacity: 1;
    transition: opacity 0.3s ease; /* Transição suave de 0.3 segundos */
}

.DivModalSuport{
    width: 50%;
    background-color: white;
    padding: 2vw;
    border-radius: 8px;
}

.demandaTitle{
    display: flex;
    align-self: flex-start;
    color: grey;
    margin: 3px;
    font-size: 0.8rem;
    margin-top: 1vw;
}


@media (min-width: 0px) {

    .DivModalSuport{
        width: 92%;
    }
  }


  @media (min-width: 1200px) {

    .DivModalSuport{
        width: 50%;
    }
  }
