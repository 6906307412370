table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 1rem;
    border-radius: 5px;
  }
  
  thead {
    position: sticky;
    top: 0;
    background-color: white;
    width: 100%;
    z-index: 1;
  }

  .divTabela{

    padding: 10px;
    transition: 1s;
    width: 100%;
  }

  .bgTabela{
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    width: 100%;
    padding-right: 20px;
    transition: 1s;
  }


  .botaoTable{
    border: 1px solid #9E3FFD;
    padding: 10px;
    border-radius: 5px;
    text-align: center
  }
  
  th, td {
    padding: 8px;
    border: 1px solid rgb(255, 255, 255);
    border-bottom: none;
    border-top: none;
    text-align: left;
    cursor: pointer;
  }
  
  th {
    text-align: left;
    background-color: #ffffff;
    color: #9E3FFD;
    border-radius: 5px;
    font-weight: 600;
  }
  
  tbody > tr:nth-child(even) {
    background-color: rgb(245, 245, 245);
    transition: 1s;
    border-radius: 5px;
  }
  
  tbody > tr:nth-child(even):hover {
    background-color: #9e3ffd6e;
    color: white;
    border-radius: 5px;
  }

  tbody > tr:nth-child(even):hover .botaoTable {
    background-color: #ffffff;
    color: #9E3FFD;
    transition: 1s;
  }
  
  tbody > tr:nth-child(odd):hover .botaoTable {
    background-color: #ffffff;
    color: #9E3FFD;
    transition: 1s;
  }
  

  tbody > tr:nth-child(odd) {
    background-color: #fff;
    transition: 1s;
    border-radius: 5px;
  }
  
  tbody > tr:nth-child(odd):hover {
    background-color: #9E3FFD6e;
    color: white;
    border-radius: 5px;
  }

  .searchContainer{
    display: flex;
    gap: 5px 5px;
    align-items: center;
    height: 30px;
    width: 27%;
    border: 1px solid #9E3FFD;
    background-color: white;
    border-radius: 15px;
    padding-left: 5px;
  }
  

  .searchContainer input{
    width: 90%;
    border-radius: 8px;
    border: none;
    background-color: transparent;
    height: 100%;
  }


  textarea:focus, input:focus{
    outline: none;
}

