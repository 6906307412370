.PagePerfil{

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;
    width: 100%;
}

.whitecard {
    background-color: white;
    border-radius: 2vh;
    align-self: flex-start;
    width: 85%;
}

.tableForm{
    display: flex; 
    justify-content: flex-start; 
    flex-direction:column; 
    align-items:flex-start
}

.imageCircle {

    border: 2px solid #9E3FFD;
    width: 100px;
    height: 100px;
    border-radius: 2vh;

    display: flex;
    justify-content: center;
    align-items: center;
}

.imageCircle span {
    color: gray
}



@media (min-width: 1000px) {

    .BoxInputPacote{
        width: 50%;
    }

    .titleBoxPacote {
        font-size: 2vw;
    }

    
    .subTitleBoxPacote {
        font-size: 1vw;
    }
  }