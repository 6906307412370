.BoxInputCotacao{
    background-color: white;
    border-radius: 10px;
    width: 50%;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    transition: 1s;
    padding: 1vw;
}




.Box:hover{
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    transition: 1s;
}

.titleBox{
    font-size: 1vw;
    width: 100%;
}

.subTitleBox{
    font-size: 0.7vw;
    color: grey;
    width: 100%;
}

@media (min-width: 0px) {
    .BoxInputCotacao {
      width: 80vw ;
      padding: 3vh 3vh 3vh 3vh;
    }

    .titleBox{
        font-size: 18px !important;
    }
    
    .subTitleBox{
        font-size: 10px !important;
    }
  }

  @media (min-width: 700px) {
    .BoxInputCotacao {
      width: 80%;
      padding: 2vh 1vw 2vh 1vw;
    }

    .titleBox{
        font-size: 20px !important;
    }
    
    .subTitleBox{
        font-size: 15px !important;
    }
  }

  @media (min-width: 1200px) {
    .BoxInputCotacao {
      width: 50%;
      padding: 2vw 1vw 2vw 1vw;
    }

    .titleBox{
        font-size: 20px !important;
    }
    
    .subTitleBox{
        font-size: 15px !important;
    }
  }
