.BoxResultTransportadoras{
    width: max-content;
    border: none;
    padding: 1%;
    text-align: left;
    border-radius: 10px;
    transition: 1s;
    border: 1px solid #949494;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    
}

.BoxResultTransportadoras:hover{
    border: 1px solid white;
    transition: 1s;
    background-color: white;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.BoxResultTransportadoras:hover .fotoResultTransportadoras{
   border: 1px solid #9E3FFD;
}


.fotoResultTransportadoras{
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: 1px solid #ffffff;
    border-radius: 10px;
    transition: 1s;
}



@media (min-width: 0px) {
    .titleResultTransportadoras{
        font-size: 5vw;
    }
    
    .valorResultTransportadoras{
        font-size: 3vw;
    }
    
    .SubTitleResultTransportadoras{
        color: grey;
    }
  }

  @media (min-width: 700px) {
    .titleResultTransportadoras{
        font-size: 3vw;
    }
    
    .valorResultTransportadoras{
        font-size: 1vw;
    }
    
    .SubTitleResultTransportadoras{
        color: grey;
    }
  }

  @media (min-width: 1200px) {
    .titleResultTransportadoras{
        font-size: 1vw;
    }
    
    .valorResultTransportadoras{
        font-size: 1.5vw;
    }
    
    .SubTitleResultTransportadoras{
        color: grey;
    }
  }
