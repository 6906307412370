.BoxInputLogin{
    padding: 1vw 2vw 2vw 2vw;
    border-radius: 10px;
    width: 30%;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    transition: 1s;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.titleBox{
    font-size: 1vw;
    width: 100%;
}

.subTitleBox{
    font-size: 0.7vw;
    color: grey;
    width: 100%;
}

.regulatText {
    color: gray;
    font-weight: bold;
}

.loginLink {
    color: blueviolet;
    cursor: pointer;
}

.loginLink:hover {

    opacity: 50%;
}

@media (min-width: 0px) {
    .BoxInputLogin{
        width: 90%;
    }
  }

  @media (min-width: 700px) {
    .BoxInputLogin{
        width: 50%;
    }
  }

  @media (min-width: 1200px) {
    .BoxInputLogin{
        width: 30%;
    }
  }
