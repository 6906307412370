.titlePage{
    color: black;
    text-align: left;
    font-size: 35px;
    line-height: 37px;
    
}

.subTitlePage{
    color: grey;
    text-align: left;
    font-size: 20px;
    padding-right: 30px;
}

@media (min-width: 1024px) {
    
    .titlePage{
        color: black;
        text-align: left;
        font-size: 30px;
    }
    
    .subTitlePage{
        color: grey;
        text-align: left;
        font-size: 15px;
    }

  }


.TitlePage{
    display: 'flex'; 
    flex-direction: 'column';
    justify-content: 'left'; 
    align-content: 'flex-start';
}