.divResultLotacao{
    background-color: white;
    width: 100%;
    border-radius: 10px;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.342);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
    gap: 2% 10px;
    padding: 8px 8px 8px 0px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: 1s;
}

.divResultLotacao:hover{
    background-color: white;
    width: 100%;
    box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.6);
    transition: 1s;
}

.titleDiv{
    font-size: 8px;
    color: grey;
    width: 100%;
    overflow-x: visible;
    text-align: left;
    font-weight: 1000;
    line-height: 0px;
    margin-bottom: 8px;
}

.divInfoUser{
    width: 100px;
    border-right: solid 1px grey;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    padding: 10px;
}

.divHoraRestante{
    border: 1px solid grey;
    border-radius: 30px;
     display: flex;
    flex-direction: row;
    align-content: center;
    gap: 10px 10px;
    padding: 0px 10px 0px 10px; 
    width: 130px; 
    font-size: 12px;
    text-align: center;
    height: 30px;
}

.divTagsLotacao{
    border: 1px solid white;
    border-radius: 5px;
    display: flex;
    align-items: flex-start;
    justify-content: left;
    gap: 5px 10px;
    align-self: flex-start;
    height: 80%;
    width: 40%;
    flex-wrap: wrap;
}

.divObservacao{
    border: 1px solid white;
    border-radius: 5px;
    display: flex;
    align-items: flex-start;
    justify-content: left;
    align-self: flex-start;
    height: 80%;
    width: 15%;
    text-align: left;
    flex-wrap: wrap;
    margin-right: 20px;
}

.divObservacao h6{
    margin: 0;
    font-weight: 600;
}

.itemTagLotacao{
    height: max-content;
    width: max-content;
    padding: 1px 8px 1px 8px;
    line-height: 0px;
    margin: 0;
    font-size: 10px;
    font-weight: 1000;
    border-radius: 60px;
    background-color: rgb(224, 224, 224);
    color: rgb(105, 105, 105);
    border: none;
}


.arrowDown{
    border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 5px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-top: 3px;
  margin-bottom: 6px;
}

.divCidadesLotacao{
    font-weight: 600;
    font-size: 12px;
    height: max-content;
    width: 15%;
    text-align: center;
    margin-right: 20px;
    height: 100%;
    align-self: center;
}

.divPreco{
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

@media (max-width: 1200px){
    .divResultLotacao{
        width: 950px;
    }
}
