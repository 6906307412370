.BoxInputRastreio{
    background-color: white;
    padding: 1vw 2vw 2vw 2vw;
    border-radius: 10px;
    width: max-content;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    transition: 1s;
}

.titleBox{
    font-size: 1vw;
    width: 100%;
}

.subTitleBox{
    font-size: 0.7vw;
    color: grey;
    width: 100%;
}


@media (min-width: 0px) {
   
    .BoxInputRastreio{
        width: 90vw;
    }
    
    .titleBox{
        font-size: 1vw;
        width: 100%;
    }
    
    .subTitleBox{
        font-size: 0.7vw;
        color: grey;
        width: 100%;
    }
    
  }


  @media (min-width: 800px) {
    .BoxInputRastreio{
        width: 40vw;
    }
    
    .titleBox{
        font-size: 1vw;
        width: 100%;
    }
    
    .subTitleBox{
        font-size: 0.7vw;
        color: grey;
        width: 100%;
    }

  }
