

/* Container */
.TransparentCardContainer {

    width: 25%;
    /* height: 96px; */
    border-radius: 9px;
    border: 1px solid #9E3FFD;
    padding: 1rem;

    display: flex;
    justify-content: space-around;
}

/* Div Ícone */
.cardIcon {

    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
}

/* Div Textos */

.cardTexts {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    /* border: solid purple 2px; */
}


.cardp {

    color: #737373;
    font-size: 0.8rem;
    font-weight: 400;
    /* border: solid blue 2px; */
   
}

.cardStrong {

    color: #9E3FFD;
    font-size: 1.1rem;
    /* border: solid yellow 2px; */

}