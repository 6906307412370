
.divColapseMenu{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    background-color: #9832ff79;
    width: 50px;
    height: 50px;
    position: fixed;
    padding: 0;
    border-radius: 50%;
    top: 10px;
    left: 85vw;
    cursor: pointer;
}

.shadowBackGround{
    background-color: 'rgba(19, 19, 19)';
    width: 100%; 
    height: 100%; 
    position: fixed;
    top: 0; 
    transition: 1s 
}