.PageConfirmarEmail{
    display: flex;
    justify-content: center;
    margin-top: 20vh;
}

.DivResulConfirmarEmail{
    background-color: white;
    height: 50vh;
    width: 50vw;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)
}