.BoxPacote {
    border: 1px solid gray;
    padding: 2vw;
    border-radius: 10px;
    width: 100%;
    /* height: 70%; */
}
.BoxInputPacote{
    background-color: white;
    border-radius: 10px;
    width: 100%;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    transition: 1s;
    padding: 14px;
}


.titleBoxPacote {
    font-size: 2vw;
    width: 100%;
}

.subTitleBoxPacote {
    font-size: 1vw;
    color: grey;
    width: 100%;
}




@media (min-width: 0px) {

    .titleBoxPacote {
        font-size: 20px;
    }

    .subTitleBoxPacote {
        font-size: 14px;
    }

  }

  @media (min-width: 1000px) {

    .BoxInputPacote{
        width: 600px;
    }

    .titleBoxPacote {
        font-size: 2vw;
    }

    
    .subTitleBoxPacote {
        font-size: 1vw;
    }
  }

  @media (min-width: 1200px) {
    
  }