.PagePassword {

    background-image: url('../../images/Ellipse\ 2.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    /* background-color: white; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}